import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconMenuAlt03(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M5 17h8m-8-5h14M5 7h8"
			/>
		</Icon>
	);
}
