import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconExpand(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M10 19H5v-5m9-9h5v5"
			/>
		</Icon>
	);
}
