import { z } from 'zod';
import { coerceBoolean } from '../../modules/search-store';
import { FacilityBrand, FacilityType } from '../../graphql';

export const schema = z.object({
	query: z.string().nullish(),
	regionId: z.string().nullish(),
	lat: z.coerce.number().nullish(),
	lon: z.coerce.number().nullish(),
	radius: z.coerce.number().default(50),
	brands: z.union([z.array(z.nativeEnum(FacilityBrand)).nullish(), z.nativeEnum(FacilityBrand)]),
	types: z.union([z.array(z.nativeEnum(FacilityType)).nullish(), z.nativeEnum(FacilityType)]),
	access: coerceBoolean().nullish(),
	rewards: coerceBoolean().nullish(),
});
